// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import Splash from '@/components/SplashScreen.vue';
import Login from '@/components/LoginPage.vue';
import Home from '@/components/HomePage.vue';
import Notification from '@/components/NotificationPage.vue';

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('loggedIn')) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: Notification,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
