// src/App.vue

<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.min.css';
</style>
