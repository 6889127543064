// src/components/RecordPopup.vue

<template>
  <div class="popup-overlay" @click.self="closePopup">
    <div class="popup-content p-3 bg-white border">
      <h5>Record Audio</h5>
      <button class="btn btn-primary mb-3" @click="toggleRecording">{{ isRecording ? 'Stop Recording' : 'Start Recording' }}</button>
      <textarea v-model="transcript" class="form-control" rows="5" readonly></textarea>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      transcript: '',
      recognition: null,
    };
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      if (!('webkitSpeechRecognition' in window)) {
        alert('Your browser does not support speech recognition.');
        return;
      }

      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = false;
      this.recognition.lang = 'en-US';

      this.recognition.onresult = (event) => {
        let finalTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          }
        }
        this.transcript = finalTranscript;
      };

      this.recognition.start();
      this.isRecording = true;
    },
    stopRecording() {
      if (this.recognition) {
        this.recognition.stop();
        this.recognition = null;
        this.isRecording = false;
      }
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  width: 90%;
  max-width: 500px;
}
</style>
