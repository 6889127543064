// src/views/Home.vue

<template>
  <div class="container">
    <h1>Home Page</h1>
    <p>Welcome, {{ user.name }}</p>
    <div class="fixed-bottom py-2 bg-light">
      <div class="container d-flex justify-content-around">
        <button @click="goToHome" class="btn btn-primary">Home</button>
        <button @click="showRecordPopup" class="btn btn-primary">Record</button>
        <button @click="goToNotifications" class="btn btn-primary">Notifications</button>
      </div>
    </div>
    <RecordPopup v-if="showPopup" @close="showPopup = false" />
  </div>
</template>

<script>
import RecordPopup from '@/components/RecordPopup.vue';

export default {
  components: {
    RecordPopup,
  },
  data() {
    return {
      user: {
        name: 'John Doe',
      },
      showPopup: false,
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/home');
    },
    showRecordPopup() {
      this.showPopup = true;
    },
    goToNotifications() {
      this.$router.push('/notifications');
    },
  },
};
</script>
