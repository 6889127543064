<template>
  <div class="container">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div class="mb-3">
        <label for="phone" class="form-label">Phone Number</label>
        <input type="text" class="form-control" id="phone" v-model="phone">
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: ''
    };
  },
  methods: {
    async login() {
      // 使用 WhatsApp 获取一次性登录密码的逻辑
      // 假设成功获取 OTP 并验证通过
      localStorage.setItem('loggedIn', true);
      this.$router.push('/home');
    }
  }
};
</script>
