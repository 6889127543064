<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <img src="@/assets/logo.png" alt="Logo" />
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
  mounted() {
    setTimeout(() => {
      this.$router.push('/login');
    }, 3000); // 3 秒后跳转到登录页面
  }
};
</script>
