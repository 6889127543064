// src/views/Notification.vue

<template>
  <div class="container">
    <h1>Notifications</h1>
    <ul class="list-group">
      <li v-for="notification in notifications" :key="notification.id" class="list-group-item">
        {{ notification.message }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      notifications: [],
    };
  },
  async created() {
    try {
      const response = await axios.get('/api/notifications');
      this.notifications = response.data;
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  },
};
</script>
